import {Component, ElementRef, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: '[input-text]',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class InputTextComponent {
  _elementRef: ElementRef;

  @Input() formControlName;
  @Input() myForm;

  constructor(elementRef: ElementRef) {
    this._elementRef = elementRef;
    this._getHostElement().classList.add('input-text');
  }

  _getHostElement(): HTMLElement {
    return this._elementRef.nativeElement as HTMLElement;
  }
}
