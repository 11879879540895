import { Deserializable } from './deserializable';

export interface ISelfie {
  id?: number;
  url?: string;
  image?: string;
  date_capture: Date;
  date_sync?: string;
  user: string;
  source?: string;
}

export class Selfie implements Deserializable {
  public id?: number;
  public url?: string;
  public image: string;
  public date_capture: Date;
  public date_sync?: Date;
  public user: string;
  public source: string = 'website';

  deserialize(input: ISelfie) {
    Object.assign(this, input);

    return this;
  }
}
