import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from '../../models';
import { AuthenticationService } from '../../services';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  newUser: User;
  password: string;
  registerForm: FormGroup;
  errors: any[];

  constructor(
    private auth: AuthenticationService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private langService : LanguageService
  ) {}

  ngOnInit() {
    this.initForm();
    this.translate.use(this.langService.getCurrentLanguageCode());
  }

  initForm() {
    this.registerForm = this.fb.group({
      email: [null, Validators.required],
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      password: [null, Validators.required],
      confirmation: [null, Validators.required],
      legalTerm: [null, Validators.requiredTrue]
    });
  }

  register() {
    if (this.registerForm.valid) {
      this.newUser = new User().deserialize({
        first_name: this.registerForm.value.first_name,
        last_name: this.registerForm.value.last_name,
        email: this.registerForm.value.email,
      });

      const password1 = this.registerForm.value.password;
      const password2 = this.registerForm.value.confirmation;

      this.auth.register(this.newUser, password1, password2).subscribe(
        () => {
          this.snackBar.open('Inscription réussie', '', {
            duration: 3000,
          });

          this.router.navigate(['/', 'login']).then();
        },
        (errorResponse: HttpErrorResponse) => {
          this.errors = errorResponse.error.non_field_errors;
        }
      );
    }
  }
}
