import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '../../services';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  errors: string[];

  returnUrl: string;

  constructor(
    private authenticationService: AuthenticationService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private langService: LanguageService
  ) {}

  ngOnInit() {
    this.initForm();
    this.translate.use(this.langService.getCurrentLanguageCode());
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  initForm() {
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  login() {
    this.errors = [];
    if (this.loginForm.valid) {
      this.authenticationService
        .login(this.loginForm.value.email, this.loginForm.value.password)
        .subscribe(
          () => {
            this.snackBar.open('Connexion réussie', '', {
              duration: 3000,
            });
            this.router.navigate([this.returnUrl]).then();
          },
          (errorResponse: HttpErrorResponse) => {
            this.errors = errorResponse.error.non_field_errors;
          }
        );
    }
  }
}
