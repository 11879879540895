import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Injectable({
    providedIn: 'root',
})

export class SchedulerService {
    private scheduleData: Schedule;

    listOfDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

    constructor(private dbService: NgxIndexedDBService) {
        this.scheduleData = new Schedule();

        dbService.getAll('scheduler').subscribe((dbData) => {
            console.log(dbData);
            if (dbData.length > 0) {
                this.scheduleData = <Schedule>dbData[0];
                console.log(this.scheduleData);
            }
        });
    }

    getScheduleData() {
        return this.scheduleData;
    }

    setScheduleData(data) {
        this.scheduleData = data;
    }

    saveSchedule() {
        this.dbService.clear('scheduler').subscribe((successDeleted) => {
            console.log('success? ', successDeleted);
        });
        this.dbService.add('scheduler', this.scheduleData).subscribe();
        console.log("Image saved to DB successfully");
        this.dbService.getAll('scheduler').subscribe((dbData) => {
            console.log(dbData);
        });
    }

    setDayDetails(day: string, data: DayDetails) {
        this.scheduleData.scheduleDetails[day.toLowerCase()] == data;
    }

    setDayToggledData(day: string, isToggled: boolean) {
        this.scheduleData.scheduleDetails[day.toLowerCase()].isToggled = isToggled;
    }

    setDayEnabledData(day: string) {
        this.scheduleData.scheduleDetails[day.toLowerCase()].isEnabled = true;
    }

    private clearEnableData() {
        let that = this;
        this.listOfDays.forEach(function (day) {
            day = day.toLocaleLowerCase();
            that.scheduleData.scheduleDetails[day].isEnabled = false;
        });
    }

    private updateDayToggledData() {
        let that = this;
        this.listOfDays.forEach(function (day) {
            day = day.toLocaleLowerCase();
            that.scheduleData.scheduleDetails[day].isToggled = that.scheduleData.scheduleDetails[day].isToggled && that.scheduleData.scheduleDetails[day].isEnabled;;
        });
    }

    setDayStartTimeData(day: string, time: Date) {
        day = day.toLocaleLowerCase();
        this.scheduleData.scheduleDetails[day].startTime = time;
        this.scheduleData.scheduleDetails[day].startTime.setSeconds(0);
        this.scheduleData.scheduleDetails[day].startTime.setMilliseconds(0);
        return this.isEndTimeLessthanStartTime(time, this.scheduleData.scheduleDetails[day].endTime);
    }

    private isEndTimeLessthanStartTime(startTime: Date, endTime: Date) {
        if (endTime == null || endTime == undefined || startTime.getTime() === endTime.getTime()) {
            return false;
        }
        else {
            let startTimePart = startTime.toTimeString().substr(0, 5);
            let endTimePart = endTime.toTimeString().substr(0, 5);

            if (startTimePart < endTimePart) {
                return false;
            }
            else {
                return true;
            }
        }
    }

    setDayEndTimeData(day: string, time: Date) {
        day = day.toLocaleLowerCase();
        this.scheduleData.scheduleDetails[day].endTime = time;
        this.scheduleData.scheduleDetails[day].endTime.setSeconds(0);
        this.scheduleData.scheduleDetails[day].endTime.setMilliseconds(0);
    }

    setStarAndEndDateData(start: Date, end: Date) {
        this.clearEnableData();
        let stDate = this.scheduleData.startDate = start;
        let etDate = this.scheduleData.endDate = end;

        for (var d = new Date(stDate.getFullYear(), stDate.getMonth(), stDate.getDate()); d <= new Date(etDate.getFullYear(), etDate.getMonth(), etDate.getDate()); d.setDate(d.getDate() + 1)) {
            console.log(d);
            let dayName = this.listOfDays[d.getDay()];
            console.log(dayName);
            this.setDayEnabledData(dayName)
        }

        this.updateDayToggledData();

        return this.scheduleData;
    }

    getTimerIntervalForToday() {

        let startTimeInterval = -1;
        let endTimeInterval = -1;
        let msg='';
        let currentDate = new Date();
        let currentTime = currentDate.toTimeString().substr(0,5);
        currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

        let scheduleStartDate = this.scheduleData.startDate;
        scheduleStartDate = new Date(scheduleStartDate.getFullYear(), scheduleStartDate.getMonth(), scheduleStartDate.getDate());

        let scheduleEndDate = this.scheduleData.endDate;
        scheduleEndDate = new Date(scheduleEndDate.getFullYear(), scheduleEndDate.getMonth(), scheduleEndDate.getDate());

        if( currentDate >= scheduleStartDate && currentDate <= scheduleEndDate )
        {
            let currentDayName = this.listOfDays[currentDate.getDay()].toLowerCase();
            let currentDayDetails = this.scheduleData.scheduleDetails[currentDayName];
            if( currentDayDetails.isEnabled && currentDayDetails.isToggled)
            {
                currentDayDetails.startTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDayDetails.startTime.getHours(), currentDayDetails.startTime.getMinutes(), currentDayDetails.startTime.getSeconds(), currentDayDetails.startTime.getMilliseconds());
                currentDayDetails.endTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDayDetails.endTime.getHours(), currentDayDetails.endTime.getMinutes(), currentDayDetails.endTime.getSeconds(), currentDayDetails.endTime.getMilliseconds());
                let isCurrentTimeGreaterThanStarTime = currentTime >= currentDayDetails.startTime.toTimeString().substr(0,5);
                let isCurrentTimeWithinEndTime = currentTime < currentDayDetails.endTime.toTimeString().substr(0,5);

                if( isCurrentTimeGreaterThanStarTime  )
                {
                    if( isCurrentTimeWithinEndTime )
                    {
                        //added a delay for the page to be loaded, instead of 0
                        startTimeInterval = 2000;
                        endTimeInterval = currentDayDetails.endTime.getTime() - new Date().getTime();
                        msg = "Today's scheduled recording will be starting shortly.";
                    }
                }
                else if( isCurrentTimeWithinEndTime)
                {
                    startTimeInterval = currentDayDetails.startTime.getTime() - new Date().getTime();
                    endTimeInterval = currentDayDetails.endTime.getTime() - new Date().getTime();
                    msg = "Today's scheduled recording will be starting shortly.";
                }
            }
        }

        return { 'startTimeInterval' : startTimeInterval , 'endTimeInterval' : endTimeInterval, 'msg' : msg };
    }
}

class Schedule {
    startDate: any;
    endDate: any;
    scheduleDetails: { [day : string] : DayDetails; } = {};
    //scheduleDetails = new Map();

    constructor() {
        this.scheduleDetails["sunday"] = new DayDetails();
        this.scheduleDetails["monday"] = new DayDetails();
        this.scheduleDetails["tuesday"] = new DayDetails();
        this.scheduleDetails["wednesday"] = new DayDetails();
        this.scheduleDetails["thursday"] = new DayDetails();
        this.scheduleDetails["friday"] =  new DayDetails();
        this.scheduleDetails["saturday"] = new DayDetails();
    }
}

class DayDetails {
    isEnabled = false;
    isToggled = false;
    startTime: Date = new Date();
    endTime: Date = new Date();

    constructor() {
        this.startTime.setHours(0);
        this.startTime.setMinutes(0);
        this.endTime.setHours(0);
        this.endTime.setMinutes(0);
    }
}