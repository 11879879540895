import { Component } from '@angular/core';
import { AnimationService, WebCameraImage } from './animation/animation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import {  LanguageService } from './services/language.service';
import { AuthenticationService } from './services';
import { DateAdapter} from '@angular/material/core';
declare var faceapi: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AnimationService]
})
export class AppComponent {
  public isUserAuthenticated = false;
  private title = 'EmoScienS-Website';
  private snapShots : WebCameraImage[];
  private siteLanguage : string;
  private MODEL_URL = './assets/faceModels';
  private initialTesting = true;

  constructor(private animationService: AnimationService,
             private translate: TranslateService,
             private langService : LanguageService,
             private snackBar: MatSnackBar,
             private authService : AuthenticationService,
             private _adapter: DateAdapter<any>) {
    this.snapShots = animationService.snapShots;
    this.isUserAuthenticated = authService.isAuthenticated();
    this.siteLanguage = this.langService.getCurrentLanguageDescription();
    this.loadFaceModels();
  }

  useLanguage(lang)
  {
    this.langService.setCurrentLanguageCode(lang);
    this.translate.use(this.langService.getCurrentLanguageCode());
    this.siteLanguage = this.langService.getCurrentLanguageDescription();
    this._adapter.setLocale(this.langService.getCurrentLanguageCode());
  }

  async loadFaceModels() {
    console.log("starting time for loading models : " + new Date().toLocaleTimeString())
    await faceapi.loadSsdMobilenetv1Model(this.MODEL_URL);
    console.log("ending time for loading models : " + new Date().toLocaleTimeString())
    console.log(".......................started initial face testing............." + new Date().toLocaleTimeString() +".............");
    await faceapi.detectAllFaces("initialFaceDetectionloading");
    console.log(".......................ended initial face testing.........................." + new Date().toLocaleTimeString() +".............");
    this.initialTesting = false;
  }

  logout() {
    this.authService.logout().subscribe(() => {
      this.snackBar.open('Déconnexion réussie', '', {
        duration: 2000,
      });
      location.reload();
    });
  }
}
