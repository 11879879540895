import {Component, ElementRef, ViewEncapsulation} from '@angular/core';

@Component({
  selector: '[info]',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  inputs: ['color'],
  encapsulation: ViewEncapsulation.None,
})
export class InfoComponent {
  _elementRef: ElementRef;

  private _color: 'primary' | 'accent';

  get color(): 'primary' | 'accent' {
    return this._color;
  }

  set color(value: 'primary' | 'accent') {
    const colorPalette = value || 'primary';

    if (colorPalette !== this._color) {
      if (this._color) {
        this._getHostElement().classList.remove(`info--${this._color}`);
      }
      if (colorPalette) {
        this._getHostElement().classList.add(`info--${colorPalette}`);
      }

      this._color = colorPalette;
    }
  }

  constructor(elementRef: ElementRef) {
    this._elementRef = elementRef;
    this._getHostElement().classList.add('info');

    this.color = 'primary';
  }

  _getHostElement(): HTMLElement {
    return this._elementRef.nativeElement as HTMLElement;
  }
}
