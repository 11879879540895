import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
  })

export class LanguageService {

    currentLanguageCode ="fr";
    currentLanguageDesc = "Français";
    currentLanguageExtendedCode = "fr-FR";

    languageList = [
        { code: 'en', label: 'English', extendedCode: 'en-EN' },
        { code: 'fr', label: 'Français', extendedCode: 'fr-FR' }
      ];

    constructor() { }

    public setCurrentLanguageCode(langCode)
    {
        if( langCode != "" && langCode != null && langCode != undefined )
        {
            this.currentLanguageCode = this.languageList.find(f => f.code === langCode) ? langCode : this.currentLanguageCode;
            this.currentLanguageExtendedCode = this.languageList.find(f => f.code === this.currentLanguageCode).extendedCode;
        }
        else
        {
            this.currentLanguageCode = "fr";
            this.currentLanguageDesc ="Français";
        }
    }

    public getCurrentLanguageCode()
    {
        return (this.currentLanguageCode === "" || 
                this.currentLanguageCode === null || 
                this.currentLanguageCode === undefined ) 
                ?
                "fr" : 
                this.currentLanguageCode;
    }

    public getCurrentLanguageExtendedCode()
    {
        return (this.currentLanguageExtendedCode === "" || 
                this.currentLanguageExtendedCode === null || 
                this.currentLanguageExtendedCode === undefined ) 
                ?
                "fr" : 
                this.currentLanguageExtendedCode;
    }

    public getCurrentLanguageDescription()
    {
        if( this.currentLanguageCode != "" && this.currentLanguageCode != null && this.currentLanguageCode != undefined )
        {
            return this.languageList.find(f => f.code === this.currentLanguageCode).label;
        }
        else
        {
            this.currentLanguageCode = "fr";
            this.currentLanguageDesc ="Français";
            return this.currentLanguageDesc;
        }
    }
}