import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GlobalService } from './global.service';
import { User } from '../models';

interface AuthenticationResponse {
  key: string;
}

@Injectable()
export class AuthenticationService extends GlobalService {
  url_authentication = `${this.api_url}/rest-auth/login/`;
  url_logout = `${this.api_url}/rest-auth/logout/`;
  url_register = `${this.api_url}/rest-auth/registration/`;
  url_profile = `${this.api_url}/rest-auth/user/`;
  url_reset_password = `${this.api_url}/rest-auth/password/reset/`;
  url_reset_password_confirm = `${this.api_url}/rest-auth/password/reset/confirm/`;
  url_change_password = `${this.api_url}/authentication`;
  url_activate_user = `${this.api_url}/active_user`;

  TOKEN_KEY = 'token';
  USER_PROFILE_KEY = 'userProfile';

  constructor(public http: HttpClient) {
    super();
  }

  register(user: User, password1: string, password2: string): Observable<User> {
    const headers = GlobalService.getHeaders();
    const body = {
      password1: password1,
      password2: password2,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
    };

    return this.http.post<User>(this.url_register, body, { headers });
  }

  login(email: string, password: string): Observable<AuthenticationResponse> {
    const headers = GlobalService.getHeaders();
    return this.http
      .post<AuthenticationResponse>(
        this.url_authentication,
        {
          email,
          password,
        },
        { headers }
      )
      .pipe(
        tap((response: AuthenticationResponse) => {
          this.setToken(response.key);
          this.retrieveProfile().subscribe();
        })
      );
  }

  logout(): Observable<any> {
    const headers = GlobalService.getHeaders();
    return this.http
      .post<any>(`${this.url_logout}`, { headers })
      .pipe(tap(() => this.clean()));
  }

  retrieveProfile(): Observable<User> {
    const headers = GlobalService.getHeaders();
    return this.http
      .get<any>(this.url_profile, { headers })
      .pipe(
        map(data => new User().deserialize(data)),
        tap((user: User) => this.setProfile(user))
      );
  }

  askForResetPassword(email: string): Observable<any> {
    const headers = GlobalService.getHeaders();
    return this.http.post<any>(
      this.url_reset_password,
      {
        email: email,
      },
      { headers: headers }
    );
  }

  resetPassword(
    new_password1: string,
    new_password2: string,
    uid: string,
    token: string
  ): Observable<any> {
    const headers = GlobalService.getHeaders();

    const body = {
      new_password1,
      new_password2,
      uid,
      token,
    };
    return this.http
      .post<any>(this.url_reset_password_confirm, body, { headers: headers })
      .pipe(
        tap(() => {
          this.logout().subscribe();
        })
      );
  }

  activate(token: string): Observable<any> {
    const headers = GlobalService.getHeaders();
    return this.http.post<any>(
      this.url_activate_user,
      {
        activation_token: token,
      },
      { headers: headers }
    );
  }

  isAuthenticated(): boolean {
    return !!this.getToken();
  }

  getProfile(): User {
    return new User().deserialize(
      JSON.parse(localStorage.getItem(this.USER_PROFILE_KEY))
    );
  }

  setProfile(profile: User) {
    localStorage.setItem(this.USER_PROFILE_KEY, JSON.stringify(profile));
  }

  removeProfile() {
    localStorage.removeItem(this.USER_PROFILE_KEY);
  }

  setToken(token: string) {
    localStorage.setItem(this.TOKEN_KEY, token);
  }

  getToken(): string {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  removeToken() {
    localStorage.removeItem(this.TOKEN_KEY);
  }

  clean() {
    this.removeToken();
    this.removeProfile();
  }
}
