import {Component, ElementRef, Input, ViewEncapsulation} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: '[custom-form-error]',
  templateUrl: './custom-form-error.component.html',
  styleUrls: ['./custom-form-error.component.scss'],
  inputs: ['color'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomFormErrorComponent {
  _elementRef: ElementRef;

  @Input() myForm: FormGroup;

  private _color: 'primary' | 'accent';

  get color(): 'primary' | 'accent' {
    return this._color;
  }

  set color(value: 'primary' | 'accent') {
    const colorPalette = value || 'primary';

    if (colorPalette !== this._color) {
      if (this._color) {
        this._getHostElement().classList.remove(`error--${this._color}`);
      }
      if (colorPalette) {
        this._getHostElement().classList.add(`error--${colorPalette}`);
      }
      this._color = colorPalette;
    }
  }

  constructor(elementRef: ElementRef) {
    this._elementRef = elementRef;
    this._getHostElement().classList.add('error');
    this.color = 'primary';
  }

  _getHostElement(): HTMLElement {
    return this._elementRef.nativeElement as HTMLElement;
  }

  displayFormErrors(form) {
    for (const errorType in form.errors) {
      for (const error of form.errors[errorType]) {
        return error;
      }
    }
  }
}

