import { Injectable } from '@angular/core';
import { WebcamImage } from 'ngx-webcam';

export class WebCameraImage {
  // this is our data model. the "message" and "messageState" properties are used in the "banner" demo
  constructor(public Id = '',
              public snapShot: WebcamImage = null,
              public message = '',
              public messageState = 'hidden'
  ) { }

}

@Injectable()
export class AnimationService {

  snapShots: WebCameraImage[] = [];

  // the "animating" flag is set when an animation is happening. Its purpose is to disable the
  // buttons so we can't do too many things at once.
  animating: boolean = false;

  /*canAdd() {
    return this.snapShots.length < ALL_WEBCAMERAIMAGES.length && !this.animating;
  }

  canRemove() {
    return this.snapShots.length > 0 && !this.animating;
  }*/

  add( imageId,  webCamImage, isAutoSync = true) {
    let newSnapShot = new WebCameraImage( imageId, webCamImage);

    // when creating a hero, make the message visible
    /*newSnapShot.messageState = 'visible';
    newSnapShot.message = ""*/
    if( isAutoSync)
    {
      this.snapShots.pop();

      var that = this;

      setTimeout(function() { 
        that.snapShots.push(newSnapShot);
      }, 1000);
    }
    else
    {
      this.snapShots.push(newSnapShot);
    }

    // set a timeout so the message disappears after a short time
    
  }

  showFaceNotDetectedMessage(imageId, isAutoSync = true)
  {
    let lastSnapShot = this.snapShots.find(e=> e.Id === imageId);
    lastSnapShot.message ="Face Not Detected !";
    lastSnapShot.messageState ="notDetectedVisible";

    if( !isAutoSync)
    {
      setTimeout(function() { 
        lastSnapShot.messageState = "detectedHidden";
        lastSnapShot.message = ""  
      }, 1500);
    }
  }

  showFaceDetectedMessage(imageId, isAutoSync = true)
  {
    let lastSnapShot = this.snapShots.find(e=> e.Id === imageId);
    lastSnapShot.message ="Face Detected !";
    lastSnapShot.messageState ="detectedVisible";

    if( !isAutoSync)
    {
      setTimeout(function() { 
        lastSnapShot.messageState = "detectedHidden";
        lastSnapShot.message = ""  
      }, 1500);
    }
  }

  remove() {
    let lastSnapShot = this.snapShots[this.snapShots.length - 1];

    // we don't remove the snapshot immediately. instead, we set the message to visible.
    lastSnapShot.messageState = 'notDetectedVisible';
    lastSnapShot.message = "No Face Detected!";
    this.animating = true;

    var that = this; // needed in the setTimeout() callback

    // after the message has been visible for a short time, we actually remove the
    // snapshot from the list
    setTimeout(function() {
        lastSnapShot.messageState = 'notDetectedHidden';
        lastSnapShot.message = "";
        that.snapShots.length -= 1;
      that.animating = false;
    }, 1500);
  }

  clear()
  {
    this.snapShots.length = 0;
  }

}
