import {Component, ElementRef, ViewEncapsulation} from '@angular/core';

@Component({
  selector: '[app-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  inputs: ['color', 'disabled'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent {
  _elementRef: ElementRef;

  private _color: 'primary' | 'accent';

  get color(): 'primary' | 'accent' {
    return this._color;
  }
  set color(value: 'primary' | 'accent') {
    const colorPalette = value || 'primary';

    if (colorPalette !== this._color) {
      if (this._color) {
        this._getHostElement().classList.remove(`button--${this._color}`);
      }
      if (colorPalette) {
        this._getHostElement().classList.add(`button--${colorPalette}`);
      }

      this._color = colorPalette;
    }
  }

  private _disabled;

  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(disabled: boolean) {
    if (!disabled) {
      this._getHostElement().classList.remove(`button--disabled`);
    }
    if (disabled) {
      this._getHostElement().classList.add(`button--disabled`);
    }
    this._disabled = disabled;
  }

  constructor(elementRef: ElementRef) {
    this._elementRef = elementRef;
    this._getHostElement().classList.add('button');

    this.color = 'primary';
  }

  _getHostElement(): HTMLElement {
    return this._elementRef.nativeElement as HTMLElement;
  }
}
