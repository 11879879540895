import {Component, ElementRef, ViewEncapsulation} from '@angular/core';

@Component({
  selector: '[input-label]',
  templateUrl: './input-label.component.html',
  styleUrls: ['./input-label.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InputLabelComponent {
  _elementRef: ElementRef;

  constructor(elementRef: ElementRef) {
    this._elementRef = elementRef;
    this._getHostElement().classList.add('input-label');
  }

  _getHostElement(): HTMLElement {
    return this._elementRef.nativeElement as HTMLElement;
  }
}
