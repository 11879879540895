import { Component, Input, OnInit } from '@angular/core';
import {FormGroup} from "@angular/forms";
import {debounceTime} from "rxjs/operators";
import {merge} from "rxjs";

export const defaultErrors = {
  api: (message) => message,
  required: () => [`This field is required`],
  email: () => [`Email invalid`],
  emailExist: () => [`Email invalid`],
  min: (minError) =>
    [`VAlue (${minError.actual}) smaller than allowed (${minError.min}) `],
  passwordMatch: (message) => message,
  missingContactInfo: () =>
    [`You must provide at least one of the contact methods`],
};

@Component({
  selector: 'app-input-error',
  templateUrl: './input-error.component.html',
  styleUrls: ['./input-error.component.scss'],
})
export class InputErrorComponent implements OnInit {
  @Input()
  myForm: FormGroup;

  @Input() myFormControl;

  error: [];

  constructor() {}

  ngOnInit(): void {
    let control =  this.myForm.controls[this.myFormControl];
    if (control) {
      const observables = [control.statusChanges.pipe(debounceTime(100))];
      merge(...observables).subscribe(() => {
        this.checkError();
      });
    }
  }

  checkError() {
    let control =  this.myForm.controls[this.myFormControl];
    const controlErrors = control.errors;
    if (controlErrors && control.invalid) {
      const firstKey = Object.keys(controlErrors)[0];
      const getError = defaultErrors[firstKey];
      this.error = getError(controlErrors[firstKey]);
    } else {
      this.error = null;
    }
  }
}
