import { Deserializable } from './deserializable';

export interface IUser {
  id?: number;
  url?: string;
  email: string;
  is_active?: boolean;
  is_superuser?: boolean;
  first_name?: string;
  last_name?: string;
}
export class User implements Deserializable {
  public id?: number;
  public url?: string;
  public email: string;
  public is_active?: boolean;
  public is_superuser?: boolean;
  public first_name?: string;
  public last_name?: string;

  deserialize(input: IUser) {
    Object.assign(this, input);

    return this;
  }

  get name() {
    if (this.first_name && this.last_name) {
      return `${this.first_name} ${this.last_name}`;
    } else {
      return this.email;
    }
  }
}
