import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiRestGenericLibService } from './api-rest-generic-lib.service';
import { Selfie } from '../models/selfie';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SelfieService extends ApiRestGenericLibService<Selfie> {
  SELFIE_URL_BASE = `${this.api_url}/selfies`;

  constructor(public http: HttpClient) {
    super(http);
    this.c = Selfie;
    this.url = this.SELFIE_URL_BASE;
  }

  upload(selfie: Selfie, picture): Observable<Selfie> {
    const headers = new HttpHeaders({
      'Accept-Language': environment.default_language,
    });
    const formData = new FormData();
    formData.append('image', picture);
    formData.append('date_capture', selfie.date_capture.toISOString());
    formData.append('user', selfie.user);
    formData.append('source', selfie.source);
    return this.http.post<any>(this.url, formData, { headers: headers });
  }
}
