import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { WebCameraImage } from './animation.service';
  
    @Component({
    selector: 'animated-snapshots',
    template: `
    <div class="list-container">
        <div class="list-item" *ngFor="let webcamImage of snapShots; let i = index"
             [@simpleFadeAnimation]="'in'">
          <img id="{{ 'LastPicture_' + i }}"
              (load)="OnLastPhotoLoaded($event)"
              [src]="webcamImage.snapShot.imageAsDataUrl"/>
          <div class="message" [@messageAnimation]="webcamImage.messageState">
            {{ webcamImage.message }}
          </div>
        </div>
    </div>
    `,
    styleUrls: ['./animation.component.css'],
    animations: [
      // the fade-in/fade-out animation.
      // the trigger name does not matter, but it must match the name used in the [@...] attribute in the template.
      trigger('simpleFadeAnimation', [
  
        // the "in" style determines how the element looks when it is visible.
        // the style name "in" must match the value of the [@simpleFadeAnimation]="'in'" attribute in the template
        state('in', style({opacity: 1})),
  
        // fade in when created. this could also be written as transition('void => *')
        transition(':enter', [
          // the styles start from this point when the element appears
          style({opacity: 0}),
          // and animate toward the "in" state above
          animate(600 )
        ]),
  
        // fade out when destroyed. this could also be written as transition('void => *')
        transition(':leave',
          // fading out uses a different syntax, with the "style" being passed into animate()
          animate(600, style({opacity: 0})))
      ]),
  
      // the animation for the message
      trigger('messageAnimation', [
  
        // this defines the "resting" styles for the "visible" state
        // (i.e., what styles the message element has when visible)
        state('notDetectedVisible', style({
          opacity: 0.9,
          display: 'block',
          backgroundColor: 'red'
        })),

        state('detectedVisible', style({
          opacity: 0.9,
          display: 'block',
          backgroundColor: 'green'
        })),
  
        // this defines the "resting" styles for the "hidden" state.
        // (i.e., what styles the message element has when hidden)
        state('notDetectedHidden',   style({
          opacity: 0,
          display: 'none',
          backgroundColor: 'red'
        })),

        state('detectedHidden',   style({
          opacity: 0,
          display: 'none',
          backgroundColor: 'green'
        })),
  
        // transition from "hidden" to "visible" states using an animation
        transition('notDetectedHidden => notDetectedVisible', animate('300ms ease-in')),
        transition('detectedHidden => detectedVisible', animate('300ms ease-in')),
  
        // transition from "visible" to "hidden" similarly
        transition('notDetectedVisible => notDetectedHidden', animate('300ms ease-out')),
        transition('detectedVisible => detectedHidden', animate('300ms ease-out'))
  
      ]),
  
    ]
  })
  export class ListWebCamImagesComponent implements OnInit{
     @Input() snapShots: WebCameraImage[];
     @Output("onLoad") parentFun: EventEmitter<any> = new EventEmitter();

     constructor() { }

    ngOnInit() {
    }

     OnLastPhotoLoaded(event)
     {
       this.parentFun.emit(event);
     }
  }
  