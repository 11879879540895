import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { SelfieService } from '../../services/selfie.service';
import { Selfie } from '../../models/selfie';
import { environment } from '../../../environments/environment';
import { AnimationService, WebCameraImage } from '../../animation/animation.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/language.service';
import { SchedulerService } from '../../services/scheduler.service';
import { AppComponent } from '../../app.component';
import { DateAdapter } from '@angular/material/core';
import { Title } from '@angular/platform-browser';

declare var cv: any;
declare var faceapi: any;

export interface IWebcamImage {
  name: string;
  imageAsDataUrl: string;
  mimeType: string;
  imageData: ImageData;
  date_capture: Date;
  id?: number;

}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [AnimationService]
})



export class HomeComponent implements OnInit {
  listOfDays = this.schedulerService.listOfDays;
  minDate: Date;
  isFirstPanelEnabled: false;
  timerIntervalDetails: any;
  scheduleData: any;
  snapShots: WebCameraImage[];
  step: 'AUTHORIZATION' | 'CAMERA' | 'REVIEW' | 'FINISH' | 'SYNCING' = 'AUTHORIZATION';
  // private MODEL_URL = './assets/faceModels';
  numberOfFilesSynced: number = 0;
  numberOfFilesSyncing: number = 0;
  lastPhotoTaken: WebcamImage = null;
  // toggle webcam on/off
  showWebcam = false;
  allowCameraSwitch = true;
  multipleWebcamsAvailable = false;
  deviceId: string;
  videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  errors: WebcamInitError[] = [];
  // latest snapshot
  webcamImages: WebcamImage[] = [];
  // List we will sync to the server
  listToReview;
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  recordSubscription: Subscription;
  displayPreviewTab = true;
  timeBetweenCapture = 60;
  autoSyncServer = true;
  recordRunning = false;
  lastPicture: WebcamImage;
  isFaceDetected = false;
  isErrorOnSaveSchedule = false;
  SaveScheduleErrorMsg = '';
  isAneScheduleForToday = false;
  todaysScheduleMsg = '';
  schedulerStartTimeIntervalHandler;
  schedulerEndTimeIntervalHandler;
  recordingTitleIntervalHandler: any = null;
  //initialTesting = true;

  constructor(
    private auth: AuthenticationService,
    private dbService: NgxIndexedDBService,
    private selfieService: SelfieService,
    private animationService: AnimationService,
    private translate: TranslateService,
    private langService: LanguageService,
    private schedulerService: SchedulerService,
    private appComponent: AppComponent,
    private _adapter: DateAdapter<any>,
    private titleService: Title,
  ) {
    this.snapShots = animationService.snapShots;
    appComponent.isUserAuthenticated = true;
  }

  ngOnInit() {
    console.log('Test');
    if (this.isElectronApp)// && process.platform === 'darwin')
    {
      const electron = (<any>window).require('electron');
      electron.ipcRenderer.send('request-camera');

    }

    this.minDate = new Date();
    this._adapter.setLocale(this.langService.getCurrentLanguageCode());
    this.translate.use(this.langService.getCurrentLanguageCode());
    this.timeBetweenCapture = environment.intervalBetweenCapture / 1000;
    WebcamUtil.getAvailableVideoInputs().then(
      (mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      }
    );

    faceapi.env.monkeyPatch({
      createCanvasElement: () => document.createElement('canvas'),
      createImageElement: () => document.createElement('img')
    });
  }

  async OnLastPhotoLoaded(event) {
    var target = event.target || event.srcElement || event.currentTarget;
    var idAttr = target.attributes.id;
    var idValue = idAttr.nodeValue;
    this.isFaceDetected = false;
    console.log("Image : " + idValue + " loaded");
    const input = document.getElementById(idValue)
    let fullFaceDescriptions = await faceapi.detectAllFaces(input);
    this.isFaceDetected = fullFaceDescriptions?.length > 0;
    if (this.isFaceDetected) {
      this.animationService.showFaceDetectedMessage(idValue, this.autoSyncServer);
    }
    else {
      this.animationService.showFaceNotDetectedMessage(idValue, this.autoSyncServer);
    }
    this.SyncImageToServer();
  }

  formatImage(webcamImage) {
    const image: IWebcamImage = {
      name: 'name',
      imageAsDataUrl: webcamImage.imageAsDataUrl,
      mimeType: 'image/jpeg',
      imageData: webcamImage.imageData,
      date_capture: new Date(),
    };
    return image;
  }

  savePicture(webcamImage: WebcamImage) {
    const toSaveImage: IWebcamImage = this.formatImage(webcamImage);
    this.dbService.add('file', toSaveImage).subscribe();
    console.log("Image saved to DB successfully");
  }

  triggerSnapshot(): void {
    this.trigger.next();
  }

  authorizeWebcam(): void {
    this.showWebcam = true;
    this.step = 'CAMERA';
    this.scheduleData = this.schedulerService.getScheduleData();
    if (this.scheduleData.startDate != null && this.scheduleData.startDate != undefined &&
      this.scheduleData.endDate != null && this.scheduleData.endDate != undefined) {
      this.calculateTimerDetails();
    }
  }

  calculateTimerDetails() {
    this.timerIntervalDetails = this.schedulerService.getTimerIntervalForToday();
    if (this.timerIntervalDetails.startTimeInterval != "-1" && !this.recordRunning) {
      this.isAneScheduleForToday = true;
      this.todaysScheduleMsg = this.timerIntervalDetails.msg;

      if (this.recordRunning) {
        if (this.schedulerStartTimeIntervalHandler != null &&
          this.schedulerEndTimeIntervalHandler != null) {
          clearTimeout(this.schedulerStartTimeIntervalHandler);
          clearTimeout(this.schedulerEndTimeIntervalHandler);
        }
      }
      else {

        this.schedulerStartTimeIntervalHandler = setTimeout(() => {
          this.startRecord();
        }, this.timerIntervalDetails.startTimeInterval);

        this.schedulerStartTimeIntervalHandler = setTimeout(() => {
          this.stopRecord();
        }, this.timerIntervalDetails.endTimeInterval);
      }
    }
  }

  handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  handleImage(webcamImage: WebcamImage): void {
    this.lastPhotoTaken = webcamImage;
    if (this.autoSyncServer) {
      this.webcamImages.pop();
    }

    this.webcamImages.push(webcamImage);
    this.animationService.add("LastPicture_" + (this.webcamImages.length - 1), webcamImage, this.autoSyncServer);
  }

  SyncImageToServer() {
    if (this.autoSyncServer) {
      if (this.isFaceDetected) {
        const file = this.formatImage(this.lastPhotoTaken);

        const selfie = new Selfie().deserialize({
          date_capture: file.date_capture,
          user: this.auth.getProfile().url,
        });

        fetch(this.lastPhotoTaken.imageAsDataUrl).then(res => {
          res.blob().then(blob => {
            const fileImage = new File(
              [blob],
              `${file.date_capture.toISOString()}.jpg`
            );
            this.selfieService.upload(selfie, fileImage).subscribe();
          });
        });
      }
    }
    else {
      if (this.isFaceDetected) {
        this.savePicture(this.lastPhotoTaken);
      }
      else {
        this.webcamImages.pop();
        this.animationService.remove();
        this.lastPicture = null;
      }
    }
  }

  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  get isElectronApp(): boolean {
    return !!window.navigator.userAgent.match(/Electron/);
  }

  startRecord() {
    let intervalCounter = 0;
    let that = this;
    if (this.isElectronApp) {
      const electron = (<any>window).require('electron');
      electron.ipcRenderer.send('recordingStarted');
    }
    setTimeout(() => {
      this.isAneScheduleForToday = false;
    }, 10000);

    this.recordingTitleIntervalHandler = setInterval(() => {
      if (intervalCounter % 2 == 0) {
        that.titleService.setTitle("EmoScienSWebsite");
        document.getElementById('appFavicon').setAttribute('href', './favicon.ico');
      }
      else {
        document.getElementById('appFavicon').setAttribute('href', './recording.ico');
        that.titleService.setTitle("Recording......");
      }
      intervalCounter++;
      console.log("something");
    }, 1000);

    this.step = "CAMERA";
    this.webcamImages = [];
    this.animationService.clear();
    console.log(this.timeBetweenCapture);
    this.recordRunning = true;
    if (this.recordSubscription) {
      this.recordSubscription.unsubscribe();
    }
    this.recordSubscription = interval(this.timeBetweenCapture * 1000).subscribe(() => {
      console.log('Phootooooo');
      this.triggerSnapshot();
    });
  }

  clearList() {
    this.webcamImages = [];
    this.dbService.clear('file');
    this.animationService.clear();
  }

  removeFromList(id) {
    this.dbService.delete('file', id).subscribe();

    let index = 0;
    for (const image of this.listToReview) {
      if (image.id === id) {
        this.listToReview.splice(index, 1);
        break;
      }
      index += 1;
    }

    this.dbService.count('file').subscribe(
      (count) => {
        if (count === 0) {
          this.step = 'FINISH';
          this.clearList();
        }
      }
    );
  }

  stopRecord() {
    if (this.isElectronApp) {
      const electron = (<any>window).require('electron');
      electron.ipcRenderer.send('recordingStopped');
    }
    this.recordRunning = false;
    document.getElementById('appFavicon').setAttribute('href', './favicon.ico');
    this.schedulerEndTimeIntervalHandler = this.schedulerStartTimeIntervalHandler = null;
    this.titleService.setTitle("EmoScienSWebsite");
    if (this.recordingTitleIntervalHandler != null) {
      clearInterval(this.recordingTitleIntervalHandler);
    }
    this.recordSubscription.unsubscribe();
  }

  reviewSyncData() {
    this.step = 'REVIEW';
    this.listToReview = [];
    this.dbService.getAll('file').subscribe(
      (files: IWebcamImage[]) => {
        files.forEach(
          (file: IWebcamImage) => {
            const webcamImage = {
              imageAsDataUrl: file.imageAsDataUrl,
              id: file.id
            };
            this.listToReview.push(webcamImage);
          }
        )
      }
    );
  }

  syncServer() {
    this.step = 'SYNCING';

    this.numberOfFilesSynced = 0;
    this.dbService.count('file').subscribe(
      (count) => {
        this.numberOfFilesSyncing = count;
      }
    );

    this.dbService.getAll('file').subscribe((files: IWebcamImage[]) => {
      files.forEach((file: IWebcamImage) => {
        const webcamImage = new WebcamImage(
          file.imageAsDataUrl,
          file.mimeType,
          file.imageData
        );

        const selfie = new Selfie().deserialize({
          date_capture: file.date_capture,
          user: this.auth.getProfile().url,
        });

        fetch(webcamImage.imageAsDataUrl).then(res => {
          res.blob().then(blob => {
            const fileImage = new File(
              [blob],
              `${file.date_capture.toISOString()}.jpg`
            );
            this.selfieService.upload(selfie, fileImage).subscribe(() => {
              this.removeFromList(file.id);
              this.numberOfFilesSynced += 1;
            });
          });
        });
      });
    });
  }

  pushImageToServer() {

  }

  getModeOfProgressBar() {
    if (this.numberOfFilesSyncing != 0) {
      return 'determinate';
    } else {
      return 'indeterminate';
    }
  }

  getValueOfProgressBar() {
    if (this.numberOfFilesSyncing != 0) {
      return (this.numberOfFilesSynced / this.numberOfFilesSyncing) * 100;
    } else {
      return 0;
    }
  }

  togglePreviewTab() {
    this.displayPreviewTab = !this.displayPreviewTab;
  }

  getDisplayMessageForToggleDiv() {
    if (this.displayPreviewTab) {
      return 'Hide settings';
    }
    else {
      return 'Show settings';
    }
  }

  dateChangedEvent(event) {
    if (this.scheduleData.startDate !== null && this.scheduleData.endDate !== null) {
      this.scheduleData = this.schedulerService.setStarAndEndDateData(this.scheduleData.startDate, this.scheduleData.endDate);
    }
  }

  OnDayToggled(event, day) {
    this.schedulerService.setDayToggledData(day, event.source._checked);
  }

  OnDayTimeChanged(day, isStarTime, time) {
    if (isStarTime) {
      let isEndTimeToBeModified = this.schedulerService.setDayStartTimeData(day, time);
      if (isEndTimeToBeModified)
        this.updateEndTime(day, time);
    }
    else
      this.schedulerService.setDayEndTimeData(day, time);
  }

  updateEndTime(day, time) {
    day = day.toLocaleLowerCase();
    this.scheduleData.scheduleDetails[day].endTime = time;
  }

  saveSchedule() {
    this.schedulerService.saveSchedule();
    this.calculateTimerDetails();
  }
}
