import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { Error401Interceptor, TokenInterceptor } from './interceptors';
import { AuthenticationService } from './services';
import { ListWebCamImagesComponent } from './animation/animation.component';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WebcamModule } from 'ngx-webcam';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageService } from './services/language.service';
import { SchedulerService } from './services/scheduler.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatExpansionModule } from '@angular/material/expansion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { InputErrorComponent } from './components/shared/input-error/input-error.component';
import { ButtonComponent } from './components/shared/directives/button/button.component';
import { InputLabelComponent } from './components/shared/directives/inputs/input-label/input-label.component';
import { InputTextComponent } from './components/shared/directives/inputs/input-text/input-text.component';
import { CustomFormErrorComponent } from './components/shared/directives/texts/custom-form-error/custom-form-error.component';
import { InfoComponent } from './components/shared/directives/texts/info/info.component';
import { TitleComponent } from './components/shared/directives/texts/title/title.component';

const dbConfig: DBConfig = {
  name: 'MyDb',
  version: 2,
  objectStoresMeta: [
    {
      store: 'file',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'name', keypath: 'name', options: { unique: false } },
        {
          name: 'imageAsDataUrl',
          keypath: 'imageAsDataUrl',
          options: { unique: false },
        },
        { name: 'mimeType', keypath: 'mimeType', options: { unique: false } },
        { name: 'imageData', keypath: 'imageData', options: { unique: false } },
        {
          name: 'date_capture',
          keypath: 'date_capture',
          options: { unique: false },
        },
      ],
    },
    {
      store: 'scheduler',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'schedule', keypath: 'schedule', options: { unique: false } }
      ],
    }
  ],
};

export function TranslationLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?cb=' + new Date().toISOString().replace(/\.|:|-/g,''));
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    ListWebCamImagesComponent,
    InputErrorComponent,
    ButtonComponent,
    InputLabelComponent,
    InputTextComponent,
    CustomFormErrorComponent,
    InfoComponent,
    TitleComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    MatCardModule,
    WebcamModule,
    MatButtonToggleModule,
    FormsModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: TranslationLoaderFactory, deps: [HttpClient] }
    }),
    TimepickerModule.forRoot(),
    NgbModule,
    MatExpansionModule,
    FormsModule,
    AlertModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: Error401Interceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    AuthenticationService,
    LanguageService,
    SchedulerService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
